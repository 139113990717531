import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {Message} from "primereact/message";
import {exportFile, formValid, getKeyValue, isAdmin} from "../../utils/Utils";
import {Loading, Tooltip} from "../../utils/InlineComponents";
import {Dialog} from "primereact/dialog";
import {Messages} from "primereact/messages";
import Number from "../Common/Number";
import {AppContext} from "../../HomePage";
import {Dropdown} from "primereact/dropdown";
import {protectionStrategies} from "../../utils/Constants";
import {SelectButton} from "primereact/selectbutton";
import {TabPanel, TabView} from "primereact/tabview";
import {FileUpload} from "primereact/fileupload";
import {ProductExcelImportService} from "../../service/ProductExcelImportService";
import {CampaignService} from "../../service/CampaignService";
import {CleanupService} from "../../service/CleanupService";
import {CampaignContext} from "../../App";
import {TemplateService} from "../../service/TemplateService";

const CampaignReprice = (props) =>{

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const messages = useRef(null);

    const campaignService = new CampaignService();

    const [pricingStrategy, setPricingStrategy] = useState({
        reduction_type : "",
        reduction_direction : 1,
        reduction_value : 0,
        is_active : null,
        is_auto_update : null,
        protection_strategy: "min_value",
        no_competitor_strategy: false
    });
    const [formErrors, setFormErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);
    const [loadingDynamicPricingImport, setLoadingDynamicPricingImport] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const reductionTypes = [{name:t('reprice.amount'), value: 'amount'}, {name: t('reprice.ratio'), value: 'ratio'}];
    const reductionDirections = [{name:t('reprice.low'), value: 1}, {name: t('reprice.high'), value: -1}];
    const statuses = [{name:t('actions.passive'), value: false}, {name: t('actions.active'), value: true}];
    const updateTypes = [{name:t('reprice.manuel_update'), value: false}, {name: t('reprice.auto_update'), value: true}];
    const noCompetitorTypes = [{name:t('yes_no.no'), value: false}, {name: t('yes_no.yes'), value: true}];

    const templateService = new TemplateService();
    const productExcelImportService = new ProductExcelImportService();
    const cleanupService = new CleanupService();

    useEffect ( ()=>{
        let message=<React.Fragment>{t('reprice.campaign_message1')} <br/> {t('reprice.campaign_message2')} <br/> {t('reprice.campaign_message3')}</React.Fragment>;
        messages.current.show({sticky: true, closable: false, severity: 'info', summary:'', detail: message });
    },[]);

    useEffect ( ()=>{
        if (selectedCampaign && selectedCampaign.pricing_strategy)
        {
            setPricingStrategy({...selectedCampaign.pricing_strategy, reduction_direction: selectedCampaign.pricing_strategy.reduction_value >= 0 ? 1 : -1, reduction_value: Math.abs(selectedCampaign.pricing_strategy.reduction_value)});
        }
    },[selectedCampaign]);

    const onChange = (e) =>{

        const { key, value } = getKeyValue(e);

        setFormErrors({...formErrors, [key]: checkError(key, value)});
        setPricingStrategy({...pricingStrategy, [key] : value});

    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0
    };

    const checkError = (key, value) => {
        let errorText = "";

        if (key === "reduction_type") {
            errorText = value === "" ? t('validations.invalid_type') : "";
        } else if (key === "reduction_direction") {
            errorText = !(value === 1 || value === -1) ? t('validations.invalid_value') : "";
        } else if (key === "reduction_value") {
            errorText = value < 0 ? t('validations.invalid_value') : "";
        } else if (key === "protection_strategy") {
            errorText = value === "" ? t('validations.invalid_value') : "";
        } else if (key === "is_active") {
            errorText = "";
        } else if (key === "is_auto_update") {
            errorText = "";
        } else if (key == "no_competitor_strategy"){
            errorText = "";
        }

        return errorText;
    };

    const checkErrors = async (pricingStrategy) => {

        let errors = { ...formErrors };

        Object.entries(pricingStrategy).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });

        return errors;
    };

    const savePricingStrategy = () => {
        checkErrors(pricingStrategy).then(formErrors=> {
            if (formValid(formErrors)) {

                let ps = {...pricingStrategy};
                ps.reduction_value = ps.reduction_value * ps.reduction_direction;
                delete ps.reduction_direction;

                campaignService.savePricingStrategy(selectedCampaign.id, ps).then(() => {
                    selectedCampaign.pricing_strategy = {...ps};
                    props.updateCampaignList(selectedCampaign);
                    toast.current.show({severity: 'success', summary: t('reprice.save_pricing_strategy'), detail: t('message_detail.successful')});
                }).catch(error =>{
                    setError(error);
                    setError(null);
                });
            }
            else
                setFormErrors(formErrors);
        });
    };

    const applyPriceSuggestions = () => {
        setShowModal(false);
        campaignService.applyPriceSuggestions(selectedCampaign.id);
        toast.current.show({
            severity: 'success',
            summary: t('message_detail.successful'),
            detail: t('reprice.campaign_send_price_to_site')
        });
    };


    const modalYesNo = (
        <div>
            <Button label={t('yes_no.yes')} icon="pi pi-check" onClick={applyPriceSuggestions} />
            <Button label={t('yes_no.no')} icon="pi pi-times" onClick={() => {setShowModal(false)}} />
        </div>
    );

    const exportDynamicPricingTemplate = () => {
        setLoadingExcelExport(true);
        templateService.exportDynamicPricingTemplate(selectedCampaign.id).then((response) => {
            exportFile(response, "dynamic_pricing_template.xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const importDynamicPricing = ({files}) => {
        setLoadingDynamicPricingImport(true);
        const [file] = files;
        productExcelImportService.importDynamicPricing(selectedCampaign.id, file).then(() =>{
            toast.current.show({severity: 'success', summary: t('actions.import_dynamic_pricing'), detail: t('message_detail.successful') });
            setLoadingDynamicPricingImport(false);
        }).catch(error => {
            setError(error);
            setError(null);
            setLoadingDynamicPricingImport(false);
        });
    };

    const deactivateProductsWithoutDynamicPricingStrategy = () => {
        cleanupService.deactivateProductsWithoutDynamicPricingStrategy(selectedCampaign.id).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.deactivate_without_repricing_strategy'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    return <React.Fragment>
            <h3>{t('product_detail.repricing') + ' - ' + selectedCampaign.name}</h3>

            <Messages ref={messages} />
            <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)} style={{paddingTop:"0", paddingLeft:"0", paddingRight:"0"}}>
                <TabPanel header={t('product_detail.repricing')} leftIcon="pi pi-compass" >
                    <div className="p-grid">
                        <div className="p-col-4">
                            <label>{t('reprice.reduction_type')}</label>
                        </div>
                        <div className="p-col-8">
                            <SelectButton name="reduction_type" value={pricingStrategy.reduction_type} options={reductionTypes} optionLabel="name" onChange={onChange} />
                            {errorClass("reduction_type") && (<Message severity="error" text={formErrors.reduction_type}/>)}
                        </div>
                        <div className="p-col-4">
                            <label>{t('reprice.reduction_direction')}</label>
                        </div>
                        <div className="p-col-8">
                            <SelectButton name="reduction_direction" value={pricingStrategy.reduction_direction} options={reductionDirections} optionLabel="name" onChange={onChange} />
                            {errorClass("reduction_direction") && (<Message severity="error" text={formErrors.reduction_direction}/>)}
                        </div>

                        <div className="p-col-4">
                            <label htmlFor="reduction_value">{t('reprice.reduction_value')}</label>
                        </div>
                        <div className="p-col-8">
                            <Number id="reduction_value" name="reduction_value" value={pricingStrategy.reduction_value} onChange={(e) => onChange(e)} className={errorClass("reduction_value")}/>
                            {errorClass("reduction_value") && (<Message severity="error" text={formErrors.reduction_value}/>)}
                        </div>
                        <div className="p-col-4">
                            <label>{t('reprice.protection_strategy')}</label>
                            <Tooltip tooltip={t('reprice.protection_strategy_tooltip')}/>
                        </div>
                        <div className="p-col-8">
                            <Dropdown inputId="protection_strategy" name="protection_strategy" options={protectionStrategies(t)} value={pricingStrategy.protection_strategy} onChange={onChange} />
                            {errorClass("protection_strategy") && (<Message severity="error" text={formErrors.protection_strategy}/>)}
                        </div>
                        <div className="p-col-4">
                            <label>{t('reprice.is_active')}</label>
                        </div>
                        <div className="p-col-8">
                            <SelectButton name="is_active" value={pricingStrategy.is_active} options={statuses} optionLabel="name" onChange={onChange} />
                            {errorClass("is_active") && (<Message severity="error" text={formErrors.is_active}/>)}
                        </div>
                        <div className="p-col-4">
                            <label>{t('reprice.is_auto_update')}</label>
                            <Tooltip tooltip={t('reprice.send_price_to_site_tooltip')}/>
                        </div>
                        <div className="p-col-8">
                            <SelectButton name="is_auto_update" value={pricingStrategy.is_auto_update} options={updateTypes} optionLabel="name" onChange={onChange} />
                            {errorClass("is_auto_update") && (<Message severity="error" text={formErrors.is_auto_update}/>)}
                        </div>
                        <div className="p-col-4">
                            <label>{t('reprice.no_competitor_strategy')}</label>
                            <Tooltip tooltip={t('reprice.no_competitor_strategy_tooltip')}/>
                        </div>
                        <div className="p-col-8">
                            <SelectButton name="no_competitor_strategy" value={pricingStrategy.no_competitor_strategy} options={noCompetitorTypes} optionLabel="name" onChange={onChange} />
                            {errorClass("no_competitor_strategy") && (<Message severity="error" text={formErrors.no_competitor_strategy}/>)}
                        </div>
                        <div className="p-col-4">
                        </div>
                        <div className="p-col-8">
                            <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={savePricingStrategy}/>
                        </div>

                        <div className="p-col-4">
                        </div>
                        <div className="p-col-8">
                            <Button label={t('reprice.send_price_to_site')} icon="pi pi-globe"
                                    className="p-button-outlined p-button-info" onClick={() => {
                                setShowModal(true)
                            }}/>
                        </div>

                        <Dialog header={t('reprice.send_price_to_site')} footer={modalYesNo} visible={showModal}
                                style={{maxWidth: '50vw', minWidth: '20vw'}} modal={true} onHide={() => {
                            setShowModal(false)
                        }}>
                            {t('dialogs.confirm_send_price_message')}
                        </Dialog>
                    </div>
                </TabPanel>
                <TabPanel header={t('product_detail.bulk_repricing_update')} leftIcon="pi pi-compass" >
                    <div className="p-grid" style={{marginTop:"1em"}}>
                        <Button icon="pi pi-file-excel" style={{width:"200px", marginRight:"2em"}} className="p-button-outlined p-button-success" label={t('actions.download_template')} onClick={exportDynamicPricingTemplate} loading={loadingExcelExport} />
                        {loadingDynamicPricingImport ? <span> <Loading width="25px" height="25px"/> </span> :
                            <FileUpload customUpload={true}
                                        mode="basic"
                                        auto={true}
                                        chooseLabel={t('actions.upload')}
                                        uploadHandler={importDynamicPricing}
                            />
                        }
                    </div>
                    {isAdmin() &&
                        <div className="p-col-12">
                            <Button icon="pi pi-ban" label={t('product_detail.deactivate_without_repricing_strategy')} className="p-button-outlined p-button-rounded p-button-danger" onClick={deactivateProductsWithoutDynamicPricingStrategy}/>
                        </div>
                    }
                </TabPanel>
            </TabView>

        </React.Fragment>;

};
export default CampaignReprice

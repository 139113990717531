import {AuthService} from "./AuthService";

export class ExportAsyncService {
    queueUrl = `queues/`;
    reportUrl = `product_generated_reports/`;
    postfix = `_generated_report`;

    exportDetailedProductList(campaign_id, startDate, endDate) {
        let body = {process_type: "detailed_product_list" + this.postfix, param: {campaign_id: campaign_id, start_date: startDate, end_date: endDate}};
        return AuthService.apiPost(this.queueUrl, body).then(res => res.data.data);
    }

    exportCompetitorPriceHistory(campaign_id, startDate, endDate) {
        let body = {process_type: "competitor_price_history" + this.postfix, param: {campaign_id: campaign_id, start_date: startDate, end_date: endDate}};
        return AuthService.apiPost(this.queueUrl, body).then(res => res.data.data);
    }

    listGeneratedReports(){
        return AuthService.apiGet(this.reportUrl + "list_generated_reports/").then(res => res.data.data);
    }

    downloadGeneratedReport(report_key){
        return AuthService.apiGet(this.reportUrl + "download_generated_report/",{responseType: 'blob', params: {report_key: report_key}}).then(res => res);
    }
}
